<template>
  <label class="app-checkbox">
    <input
      type="checkbox"
      :disabled="isDisabled"
      :checked="value"
      @click="updateCheckbox()"
    />{{ title }}
    <span
      :class="isDisabled ? 'disable' : ''"
      class="app-checkbox__checkmark"
    ></span>
  </label>
</template>

<script>
export default {
  name: "AppCheckbox",
  props: ["title", "value", "isDisabled"],
  methods: {
    updateCheckbox() {
      this.$emit("click-checkbox", !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.app-checkbox {
  position: relative;
  padding-left: 25px;
  margin-bottom: 0;
  font-family: "Hiragino Kaku Gothic Pro W3";
  font-size: 14px;
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &__checkmark {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #b9b9b9;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 6px;
      top: 25%;
      left: 50%;
      transform: translateX(-50%) rotate(-50deg);
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
    }
  }

  &__checkmark.disable {
    opacity: 0.4;
  }

  input:checked ~ .app-checkbox__checkmark {
    background-color: #464d77;
    border-color: #464d77;
  }
  input:checked ~ .app-checkbox__checkmark:after {
    opacity: 1;
  }
}

@media only screen and (min-width: 1200px) {
  .app-checkbox {
    font-size: 16px;
  }
}
</style>
