<template>
  <div class="order-list">
    <card-header-body ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <ModalLayout ref="orderDisabled">
        <p class="order-disabled-text f-w3">
          ランクが違うため選択できません。Superオファーの編集を行うか、他のSuperオファーを選択、新規作成おねがいします
        </p>
      </ModalLayout>
      <ModalLayout ref="order-edit">
        <p class="order-edit-text">こちらのSuperオファーを編集しますか？</p>
        <div class="d-flex justify-content-center">
          <button @click.prevent="editOrder" class="btn-edit">はい</button>
          <button @click="cancelEditOrder" class="btn-cancel">いいえ</button>
        </div>
      </ModalLayout>
      <ModalLayout ref="notExistOrderForCast">
        <p class="order-disabled-text f-w3" v-if="cast">
          {{
            cast.user.nickname
          }}さんのレベルに該当しているSuperオファーを出してください
        </p>
      </ModalLayout>
      <div class="d-flex flex-wrap order-list__list" id="order-list">
        <div
          class="order-list__item"
          v-for="(order, index) in orderList"
          :key="index"
        >
          <div
            :class="{
              'order-list__item--disabled__none': !profileId,
              'order-list__item--confirm':
                order.status_id == ADS_STATUS_VALUE.CONFIRMED,
              'order-list__item--disabled': !(
                profileId &&
                cast &&
                !(
                  order.status !== ADS_STATUS_TEXT[ADS_STATUS_VALUE.OPEN] ||
                  order.number_of_applicants === order.female_participants ||
                  checkDesignation(order)
                ) &&
                order.female_golf_ranks.includes(cast.user.female_golf_rank) &&
                order.female_prego_ranks.includes(cast.user.female_prego_rank)
              )
            }"
          >
            <div class="order-list__status d-flex" @click="showModal(order)">
              <AppCheckbox
                v-if="
                  profileId &&
                    cast &&
                    !(
                      order.status !== ADS_STATUS_TEXT[ADS_STATUS_VALUE.OPEN] ||
                      order.number_of_applicants ===
                        order.female_participants ||
                      checkDesignation(order)
                    ) &&
                    order.female_golf_ranks.includes(
                      cast.user.female_golf_rank
                    ) &&
                    order.female_prego_ranks.includes(
                      cast.user.female_prego_rank
                    )
                "
                v-model="order.isConfirm"
                :title="order.status"
                @click-checkbox="order.isConfirm = $event"
              />
              <div
                v-else
                class="span-status f-w3"
                @click.prevent="showModal(order)"
                :class="{
                  'span-status-red':
                    order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL,
                  'span-status-gray':
                    order.status_id == ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id == ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON ||
                    order.status_id == ADS_STATUS_VALUE.ASSEMBLED ||
                    order.status_id == ADS_STATUS_VALUE.MANUAL_TERMINATION ||
                    order.status_id == ADS_STATUS_VALUE.AUTO_TERMINATION,
                  'span-status-blue':
                    order.status_id == ADS_STATUS_VALUE.CONFIRMED
                }"
              >
                <span
                  class="dot"
                  :class="{
                    'dot-red': order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL,
                    'dot-gray':
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON ||
                      order.status_id == ADS_STATUS_VALUE.ASSEMBLED ||
                      order.status_id == ADS_STATUS_VALUE.MANUAL_TERMINATION ||
                      order.status_id == ADS_STATUS_VALUE.AUTO_TERMINATION,
                    'dot-blue': order.status_id == ADS_STATUS_VALUE.CONFIRMED
                  }"
                ></span>
                <span v-if="order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL">
                  オートキャンセル
                </span>
                <span v-else-if="order.status_id == ADS_STATUS_VALUE.CONFIRMED">
                  マッチング成立
                </span>
                <span
                  v-else-if="
                    order.status_id == ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM
                  "
                >
                  キャンセル
                </span>
                <span v-else>
                  {{ order.status }}
                </span>
              </div>
              <div class="d-flex">
                <div
                  class="design-order f-w3"
                  v-if="order.designation.length > 0"
                >
                  個人オファー
                </div>
                <img
                  :src="require('@/assets/image/edit.svg')"
                  alt="edit"
                  @click="editOrderModal(order.ad_id, order.designation.length)"
                  class="ml-3"
                  v-if="
                    !profileId &&
                      order.isExpand === true &&
                      order.status === ADS_STATUS_TEXT[ADS_STATUS_VALUE.OPEN] &&
                      order.number_of_applicants === 0
                  "
                />
              </div>
            </div>
            <template v-if="!order.isExpand">
              <div
                class="order-list__recap order-list__item__target recap_time"
                id="recap_time"
                @click.prevent="showModal(order)"
              >
                <p class="w-100 p-top f-w3">
                  ラウンド日時/お客様人数／ピックアップエリア
                </p>
                <p
                  class="w-100 p-bottom f-w6"
                  :class="{
                    gray:
                      order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }"
                >
                  {{
                    $dayjs(order.scheduled_meeting_datetime).format("M月D日 H")
                  }}時頃／{{ order.male_participants }}人<span
                    v-if="order.golf_area"
                    >／</span
                  >{{ order.golf_area }}
                </p>
              </div>
              <!-- :class="{
                  'recap_candidate-flame':
                    order.number_of_applicants > order.female_participants,
                  'recap_candidate-golf':
                    order.number_of_applicants == order.female_participants
                }" -->
              <div
                class="
                  order-list__recap order-list__item__target
                  recap_candidate
                "
                id="recap_candidate"
                :class="{
                  'recap_candidate-golf': order.new_apply > 0,
                  gray:
                    order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id == ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id == ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }"
                @click="
                  $router.push({
                    name: 'OrderCast',
                    query: {
                      adId: order.ad_id,
                      adStatus: order.status_id,
                      maxCast: order.female_participants,
                      isExpand_order: order.isExpand,
                      designation_length: order.designation.length,
                      isDisabled:
                        order.status !== ADS_STATUS_TEXT[ADS_STATUS_VALUE.OPEN],
                      total: numberWithCommas(
                        Number(order.assumed_settlement_points) +
                          Number(order.assumed_transportation_costs)
                      )
                    }
                  })
                "
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex flex-column">
                    <p class="w-100 p-top f-w3">応募中／オファー人数</p>
                    <div class="w-100 p-bottom f-w6 d-flex align-items-center">
                      <!-- <img
                        v-if="
                          order.number_of_applicants > order.female_participants
                        "
                        src="@/assets/image/order_icons/flame.svg"
                        alt="Flame"
                      /> -->
                      <img
                        v-if="
                          order.number_of_applicants >=
                            order.female_participants
                        "
                        src="@/assets/image/order_icons/golf.svg"
                        alt="Flame"
                      />
                      <p
                        class="mb-0"
                        :class="{
                          'text-green':
                            order.number_of_applicants >=
                            order.female_participants,
                          gray:
                            order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                            order.status_id ==
                              ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                            order.status_id ==
                              ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                            order.status_id ==
                              ADS_STATUS_VALUE.CANCEL_DUE_REASON
                        }"
                      >
                        {{ order.number_of_applicants }}人
                      </p>
                      ／{{ order.female_participants }}人
                    </div>
                  </div>
                  <b-icon icon="chevron-right"></b-icon>
                </div>
              </div>
            </template>
            <template v-if="order.isExpand" @click.prevent="showModal(order)">
              <div
                class="w-100 order-list__item__target"
                @click.prevent="showModal(order)"
              >
                <div class="text-left text-long fs-14 f-w3">
                  ラウンド日時/お客様人数
                </div>
                <div
                  class="text-right f-w3"
                  :class="{
                    gray:
                      order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }"
                >
                  {{
                    $dayjs(order.scheduled_meeting_datetime).format("M月D日 H")
                  }}時頃／{{ order.male_participants }}人
                </div>
              </div>
              <!-- :class="{
                  'recap_candidate-flame':
                    order.number_of_applicants > order.female_participants,
                  'recap_candidate-golf':
                    order.number_of_applicants == order.female_participants
                }" -->
              <div
                @click.prevent="showModal(order)"
                class="
                  order-list__item__target
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <span class="fs-14 f-w3"> ピックアップエリア </span>
                <span
                  class="fs-14 f-w3"
                  :class="{
                    gray:
                      order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }"
                >
                  {{ order.golf_area }}
                </span>
              </div>
              <div
                @click.prevent="showModal(order)"
                class="
                  order-list__item__target
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <span class="keep-word fs-14 f-w3"> プラン </span>
                <span
                  class="fs-14 f-w6"
                  :class="{
                    gray:
                      order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }"
                >
                  {{ order.plan }}
                </span>
              </div>
              <div
                @click.prevent="showModal(order)"
                class="
                  order-list__item__target
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <span class="f-w3 fs-14">女性オファー人数</span>
                <span
                  class="f-w6 fs-14"
                  :class="{
                    gray:
                      order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }"
                >
                  {{ order.female_participants }}人
                </span>
              </div>
              <div
                @click.prevent="showModal(order)"
                class="
                  order-list__item__target
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <span class="fs-14 f-w3"> ゴルフ場 </span>
                <span
                  :class="{
                    gray:
                      order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }"
                >
                  {{ order.golf_course }}
                </span>
              </div>
              <template v-if="order.designation.length == 0">
                <div
                  @click.prevent="showModal(order)"
                  class="
                    order-list__item__target
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <span> ゴルフレベル </span>
                  <span
                    :class="{
                      gray:
                        order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }"
                  >
                    {{ getName("female_golf_ranks", index) }}
                  </span>
                </div>
                <div
                  @click.prevent="showModal(order)"
                  class="
                    order-list__item__target
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <span> ランク </span>
                  <span
                    :class="{
                      gray:
                        order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }"
                  >
                    {{ getPregoRank("female_prego_ranks", index) }}
                  </span>
                </div>
              </template>
              <div
                @click.prevent="showModal(order)"
                class="
                  order-list__item__target
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <span> ゴルフシチュエーション </span>
                <span
                  :class="{
                    gray:
                      order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }"
                >
                  {{ getName("attitudes", index) }}
                </span>
              </div>
              <template v-if="order.designation.length == 0">
                <div
                  @click.prevent="showModal(order)"
                  class="
                    order-list__item__target
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <span> 年齢 </span>
                  <span
                    :class="{
                      gray:
                        order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }"
                  >
                    {{ getName("preferred_ages", index) }}
                  </span>
                </div>
                <div
                  @click.prevent="showModal(order)"
                  class="
                    order-list__item__target
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <span> タイプ </span>
                  <span>
                    {{ getName("appearances", index) }}
                  </span>
                </div>
                <div
                  @click.prevent="showModal(order)"
                  class="
                    order-list__item__target
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <span> スタイル </span>
                  <span
                    :class="{
                      gray:
                        order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }"
                  >
                    {{ getName("figures", index) }}
                  </span>
                </div>
                <div
                  @click.prevent="showModal(order)"
                  class="
                    order-list__item__target
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <span> その他 </span>
                  <span
                    :class="{
                      gray:
                        order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }"
                  >
                    {{ getName("other_preferences", index) }}
                  </span>
                </div>
              </template>
              <div
                class="order-list__item__target"
                id="candidate"
                @click="
                  $router.push({
                    name: 'OrderCast',
                    query: {
                      adId: order.ad_id,
                      adStatus: order.status_id,
                      maxCast: order.female_participants,
                      isDisabled:
                        order.status !== ADS_STATUS_TEXT[ADS_STATUS_VALUE.OPEN],
                      isExpand_order: order.isExpand,
                      designation_length: order.designation.length,
                      total: numberWithCommas(
                        Number(order.assumed_settlement_points) +
                          Number(order.assumed_transportation_costs)
                      )
                    }
                  })
                "
              >
                <div
                  class="
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <span class="fs-14 f-w3 gray">オファー状況</span>
                  <p class="f-w6 fs-14 mb-0 d-flex align-items-center">
                    <span
                      class="fs-14 f-w6 value golf-member"
                      :class="{
                        gray:
                          order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                          order.status_id ==
                            ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                          order.status_id ==
                            ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                          order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                      }"
                    >
                      <span
                        :class="{
                          'text-golf':
                            order.number_of_applicants >=
                            order.female_participants,
                          gray:
                            order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                            order.status_id ==
                              ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                            order.status_id ==
                              ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                            order.status_id ==
                              ADS_STATUS_VALUE.CANCEL_DUE_REASON
                        }"
                        >{{ order.number_of_applicants }}</span
                      >／{{ order.female_participants }}</span
                    >
                    <img
                      :class="{
                        'img-gray':
                          order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                          order.status_id ==
                            ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                          order.status_id ==
                            ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                          order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                      }"
                      :src="require('@/assets/image/right-arrow.svg')"
                      alt="see all candidate"
                    />
                  </p>
                </div>
              </div>
              <div
                v-if="order.request"
                class="order-list__item__target"
                id="note"
                @click.prevent="showModal(order)"
              >
                <p
                  class="fs-14 f-w3 mb-0 request__text"
                  :class="{
                    gray:
                      order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                      order.status_id ==
                        ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                      order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                  }"
                >
                  {{ order.request }}
                </p>
              </div>
              <b-row
                class="w-100 order-list__item__target"
                :class="{
                  gray:
                    order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                    order.status_id == ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                    order.status_id == ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                    order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                }"
                id="price"
                @click.prevent="showModal(order)"
              >
                <div
                  class="
                    w-100
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span
                    class="f-w6"
                    :class="{
                      gray:
                        order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }"
                    >合計</span
                  >
                  <span
                    class="f-w6"
                    :class="{
                      gray:
                        order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }"
                    v-if="order.designation.length > 0"
                    >{{
                      numberWithCommas(
                        Number(order.assumed_settlement_points) +
                          Number(order.assumed_transportation_costs) +
                          Number(order.designation_fee)
                      )
                    }}P</span
                  >
                  <span
                    class="f-w6"
                    :class="{
                      gray:
                        order.status_id == ADS_STATUS_VALUE.AUTO_CANCEL ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_BEFORE_CONFIRM ||
                        order.status_id ==
                          ADS_STATUS_VALUE.CANCEL_AFTER_CONFIRM ||
                        order.status_id == ADS_STATUS_VALUE.CANCEL_DUE_REASON
                    }"
                    v-else
                    >{{
                      numberWithCommas(
                        Number(order.assumed_settlement_points) +
                          Number(order.assumed_transportation_costs)
                      )
                    }}P</span
                  >
                </div>
              </b-row>
            </template>
            <!-- :class="{
                'item-flame':
                  order.number_of_applicants > order.female_participants &&
                  !order.isExpand,
                'item-golf':
                  order.number_of_applicants == order.female_participants &&
                  !order.isExpand
              }" -->
            <div
              class="
                d-flex
                justify-content-center
                order-list__item__target order-list__expand
              "
              :class="{
                'item-golf': order.new_apply > 0 && !order.isExpand
              }"
            >
              <b-button
                v-if="!order.isExpand"
                @click="changeExpand(index)"
                class="btn-expand f-w3"
              >
                詳細
                <b-icon icon="chevron-down"></b-icon>
              </b-button>
              <b-button v-else @click="changeExpand(index)" class="btn-expand">
                閉じる <b-icon icon="chevron-up"></b-icon>
              </b-button>
            </div>
            <div
              class="
                d-flex
                justify-content-center
                w-100
                d-cancel
                order-list__item__target
              "
              v-if="
                order.isExpand === true &&
                  (order.status === ADS_STATUS_TEXT[ADS_STATUS_VALUE.OPEN] ||
                    order.status ===
                      ADS_STATUS_TEXT[ADS_STATUS_VALUE.CONFIRMED])
              "
            >
              <button
                @click="
                  openModalCancelOrderMatching(
                    order.ad_id,
                    order.status_id,
                    order.scheduled_meeting_datetime
                  )
                "
                class="btn-cancel cancel-order f-w3"
              >
                オファーをキャンセル
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!orderList.length && disable === true"
        class="w-100 f-w3 no-data"
      >
        現在オーダーはありません。
      </div>
      <div
        v-if="profileId"
        class="btn-submit2"
        :class="{ disabled: !allowSubmit }"
      >
        <button @click="designateOrder()" class="f-w6" :disabled="!allowSubmit">
          <img
            :src="require('@/assets/image/document2.svg')"
            alt="document"
            v-if="allowSubmit"
          />
          <img
            :src="require('@/assets/image/document3.svg')"
            alt="document"
            v-else
          />
          オファーする
        </button>
      </div>
      <b-modal
        id="confirm-cancel__popup"
        ref="confirm-cancel__popup"
        hide-footer
        centered
      >
        <div class="text-notify d-flex f-w3 justify-content-center text-center">
          {{ textCancel }}
        </div>
        <div class="d-flex d-button">
          <button @click="cancelOrder" class="btn btn-ok f-w3">はい</button>
          <button
            @click="$refs['confirm-cancel__popup'].hide()"
            class="btn btn-cancel f-w3"
          >
            いいえ
          </button>
        </div>
      </b-modal>
      <scroll-loader :loader-method="getListOrder" :loader-disable="disable">
      </scroll-loader>
    </div>
    <CardFooter-body ref="footer" />
  </div>
</template>

<script>
import CardHeader from "../../components/CardHeader";
import CardFooter from "../../components/CardFooter";
import AppCheckbox from "@/views/Common/AppCheckbox.vue";
import { mapGetters } from "vuex";
import {
  MESSAGES,
  PERPAGE_ORDER,
  ADS_STATUS_VALUE,
  ADS_STATUS_TEXT
} from "@/utils/const";
import { numberWithCommas } from "@/utils/convert";

export default {
  name: "CastSearch",
  // props: {
  //   profileId: {
  //     type: [String, Number],
  //   },
  // },
  components: {
    "card-header-body": CardHeader,
    "CardFooter-body": CardFooter,
    AppCheckbox
  },
  metaInfo() {
    return {
      title: "Superオファー状況",
      titleTemplate: "%s | PreGo"
    };
  },
  mounted() {
    document.getElementById("order-list").scrollIntoView();
  },
  data() {
    return {
      headerTitle: {
        text: "Superオファー",
        isShowTitle: true,
        isShowLogo: false
      },
      disable: false,
      page: 1,
      MESSAGES,
      ADS_STATUS_VALUE,
      ADS_STATUS_TEXT,
      currentId: "",
      profileId: null,
      cast: null,
      textCancel: null,
      ad_id: null,
      designation: 0
    };
  },
  methods: {
    numberWithCommas,
    changeExpand(index) {
      this.orderList[index].isExpand = !this.orderList[index].isExpand;
    },
    showModal(order) {
      if (
        this.profileId &&
        !(
          this.profileId &&
          order.female_golf_ranks.includes(this.cast.user.female_golf_rank) &&
          order.female_prego_ranks.includes(this.cast.user.female_prego_rank)
        )
      ) {
        this.$refs["orderDisabled"].openModal();
      }
    },
    setDateCancel(cancel_at, deadline_date) {
      if (
        cancel_at &&
        (this.$dayjs(this.addDays(new Date(cancel_at), 7)).format(
          "YYYY-MM-DD"
        ) >= this.$dayjs(new Date()).format("YYYY-MM-DD") ||
          this.$dayjs(new Date()).format("YYYY-MM-DD") >=
            this.$dayjs(deadline_date).format("YYYY-MM-DD"))
      ) {
        return true;
      }
      return false;
    },
    openModalCancelOrderMatching(adId, status_id, deadline_date) {
      this.currentId = adId;
      if (
        status_id == ADS_STATUS_VALUE.CONFIRMED &&
        this.$dayjs(this.addDays(new Date(), 7)).format("YYYY-MM-DD") >=
          this.$dayjs(deadline_date).format("YYYY-MM-DD")
      ) {
        this.textCancel = MESSAGES.CANCEL_ORDER_MATCHINGED;
      } else {
        this.textCancel = MESSAGES.CONFIRM_CANCEL;
      }
      this.$refs["confirm-cancel__popup"].show();
    },
    addDays(dateObj, numDays) {
      dateObj.setDate(dateObj.getDate() + numDays);
      return dateObj;
    },
    getPregoRank(property, index) {
      let name = "";
      const arr = this.orderList[index][property];
      arr.forEach(element => {
        switch (element) {
          case "プレ友":
            name += "プレ友（無料）" + "、";
            break;
          default:
            name += element + "、";
            break;
        }
      });
      return name.substring(0, name.length - 1);
    },
    getName(property, index) {
      let name = "";
      const arr = this.orderList[index][property] || [];
      arr.forEach(element => {
        name += element + "、";
      });
      return name.substring(0, name.length - 1);
    },
    designateOrder() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("orderManagement/designateOrder", {
          user_id: this.profileId,
          ad_ids: this.orderList
            .filter(order => order.isConfirm)
            .map(order => order.ad_id)
        })
        .then(() => {
          this.$root.$refs.loading.finish();
          this.$toast("オファーしました。", "通知", "success");
          this.$router.push({ path: `/profile/${this.profileId}` });
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    getListOrder() {
      if (this.page === 1) return;
      this.$store
        .dispatch("orderManagement/getOrderList", {
          page: this.page,
          perpage: PERPAGE_ORDER,
          user_id: this.user?.user_id
        })
        .then(response => {
          if (response.data.total <= this.page * PERPAGE_ORDER)
            this.disable = true;
          this.page++;
        })
        .catch(() => {
          this.disable = true;
        });
    },
    editOrderModal(id, designation) {
      this.ad_id = id;
      this.designation = designation;
      this.$refs["order-edit"].openModal();
    },
    cancelEditOrder() {
      this.$refs["order-edit"].closeModal();
    },
    editOrder() {
      this.$refs["order-edit"].closeModal();
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("orderManagement/getOrderDetail", this.ad_id)
        .then(response => {
          this.$root.$refs.loading.finish();
          const order = response?.data;
          if (
            order &&
            order.status === ADS_STATUS_TEXT[ADS_STATUS_VALUE.OPEN] &&
            order.number_of_applicants === 0
          ) {
            if (this.designation > 0) {
              this.$router.push({
                path: `/my-page/orders/edit/${this.ad_id}`,
                query: { offer: "true" }
              });
            } else {
              this.$router.push({ path: `/my-page/orders/edit/${this.ad_id}` });
            }
          } else {
            this.$toast(MESSAGES.INTERVAL_ERROR, "通知", "danger");
          }
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    cancelOrder() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("orderManagement/cancelOrder", {
          user_id: this.user.user_id,
          ad_id: this.currentId
        })
        .then(response => {
          this.$refs["confirm-cancel__popup"].hide();
          if (response?.message) {
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          this.$toast("キャンセルしました。", "通知", "success");
          this.$store.dispatch("orderManagement/getOrderList", {
            page: 1,
            perpage: PERPAGE_ORDER,
            user_id: this.user?.user_id
          });
          this.page = 1;
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$refs["confirm-cancel__popup"].hide();
          this.$root.$refs.loading.finish();
        });
    },
    checkDesignation(order) {
      if (this.profileId) {
        return (order.designation || []).some(
          des => des.user_id === Number(this.profileId)
        );
      }
      return true;
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      orderList: "orderManagement/ads"
    }),
    allowSubmit() {
      let orders = this.orderList.find(order => order.isConfirm === true);
      if (orders) {
        return true;
      }
      return false;
    }
  },
  async created() {
    this.profileId = this.$route.query.profileId;
    if (this.profileId) {
      await this.$store
        .dispatch("userApp/getInfoUser", this.profileId)
        .then(res => {
          this.cast = res.data;
        });
    }
    this.$root.$refs.loading.start();
    this.$store
      .dispatch("orderManagement/getOrderList", {
        page: 1,
        perpage: PERPAGE_ORDER,
        user_id: this.user?.user_id,
        profile_id: this.profileId
      })
      .then(response => {
        this.$root.$refs.loading.finish();
        if (response.data.numberOrderOpen === 0 && this.profileId) {
          this.$refs["notExistOrderForCast"].openModal();
        }
        if (response.data.total <= PERPAGE_ORDER) this.disable = true;
        this.page++;
      })
      .catch(() => {
        this.$root.$refs.loading.finish();
        this.disable = true;
      });
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/men/orderList.scss";
#order-list {
  position: relative;
  z-index: 0;
}
.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    // height: calc(100vh - 152px);
    padding-bottom: 70px;
  }
}
</style>

<style lang="scss" scoped>
.order-edit-text {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.btn-edit {
  width: 155px;
  height: 45px;
  color: #000000;
  background-color: #e4e4e4;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 10px;
}
.btn-cancel {
  width: 155px;
  height: 45px;
  color: #ffffff;
  background-color: #464d77;
  font-size: 16px;
  border-radius: 5px;
  &.cancel-order {
    width: 100%;
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
#confirm-cancel__popup {
  .model-header {
    border-bottom: none;
  }
  .modal-dialog {
    margin: 0;
    max-width: 100%;
    justify-content: center;
    transform: none;
    transition: none;
    .modal-content {
      max-width: 520px;
      border: unset;
      background-color: $gray_light;
    }
  }
  .modal-body {
    padding: 30px 19px;
  }
  header {
    border-bottom: 0;
    padding-bottom: 0;
    padding-right: 19px;
    padding-top: 14px;
    button {
      margin-bottom: -21px;
    }
  }

  .btn {
    width: 163px;
    height: 45px;
    font-size: 16px;
    border-radius: 5px;
  }

  .btn-ok {
    color: $black;
    background-color: $gray_divider;
  }

  .btn-cancel {
    color: $white;
    background-color: $brown;
  }
  .d-button {
    padding-top: 35px;
    justify-content: space-around;
  }
  .text-notify {
    color: $black;
    font-size: 16px;
  }
}
</style>
<style lang="scss" scoped>
.gray {
  color: #818181 !important;
}
.img-gray {
  opacity: 0.5;
}
</style>
